























import { Component, Vue } from 'vue-property-decorator';
import { apireq } from '../utils/apiRequest';

@Component
export default class Login extends Vue {
  times = 0;

  pw = '';

  validate(event: Event) {
    event.preventDefault();
    apireq('post', '/auth', {
      pw: this.pw,
    })
      .then((res) => {
        if (res.data.data) {
          const SIX_HOURS = 3600 * 6 * 1000; // milliseconds
          localStorage.setItem('auth.accessToken', res.data.data);
          localStorage.setItem('auth.expiresAt', ((new Date()).getTime() + SIX_HOURS).toString());
          this.$router.push('/admin');
          return;
        }
        this.times += 1;

        if (this.times === 1) {
          alert('비밀번호를 1회 틀렸습니다');
        } else if (this.times > 1) {
          alert('비밀번호를 2회 이상 틀렸습니다. 메인페이지로 돌아갑니다');
          this.$router.push('/');
        }
      });
  }
}
